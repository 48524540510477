type options = {
  button: boolean;
  label: string;
  noIcon: boolean;
};

export const useSalesContactButton = async (options: options) => {
  const config = await useConfig();

  const linkData = computed(() => ({
    link: { url: "@showSchedulePopup", linktype: "url" },
    linkText: options?.label || config.sales_contact_link_text,
    ...options,
    component: "link-link",
  }));

  return {
    linkData,
  };
};
